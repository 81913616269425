import React, { useContext } from "react";
import { ThemeContext } from "../../context";
const Card = ({ title, des, icon }) => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className={`w-full px-12 h-80 py-10 rounded-lg ${darkMode?' bg-[#0e0f11] group hover:bg-gradient-to-b hover:from-black hover:to-[#1e2024]':'shadow-xl bg-[#d3dae3]'}  flex items-center  transition-colors duration-100 group`}>
      <div className="h-72 overflow-y-hidden">
        <div className="flex h-full flex-col gap-10 translate-y-12 group-hover:translate-y-8 transition-transform duration-500">
          <div className="w-10 h-8 flex flex-col justify-between">
            {icon ? (
              <span className="text-5xl text-designColor">{icon}</span>
            ) : (
              <>
                <span className="w-full h-[2px] rounded-lg bg-designColor inline-flex"></span>
                <span className="w-full h-[2px] rounded-lg bg-designColor inline-flex"></span>
                <span className="w-full h-[2px] rounded-lg bg-designColor inline-flex"></span>
                <span className="w-full h-[2px] rounded-lg bg-designColor inline-flex"></span>
              </>
            )}
          </div>
          <div className="flex flex-col gap-6">
            <h2 className={`text-xl md:text-2xl font-titleFont font-bold  ${darkMode?'text-gray-300':'text-darkText'}`}>
              {title}
            </h2>
            <p className="base">{des}</p>
            {/* <span className="text-2xl text-designColor">
              <HiArrowRight />
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
