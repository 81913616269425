import React, { useState,useRef, useContext } from "react";
import Title from "../layouts/Title";
import ContactLeft from "./ContactLeft";
import emailjs from "@emailjs/browser";
import { ThemeContext } from "../../context";

const Contact = () => {
  const formRef = useRef();
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  // ========== Email Validation start here ==============
  const emailValidation = () => {
    return String(email)
      .toLocaleLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };
  // ========== Email Validation end here ================

  const handleSend = (e) => {
    e.preventDefault();
    if (username === "") {
      setErrMsg("Username is required!");
    } else if (phoneNumber === "") {
      setErrMsg("Phone number is required!");
    } else if (email === "") {
      setErrMsg("Please give your Email!");
    } else if (!emailValidation(email)) {
      setErrMsg("Give a valid Email!");
    } else if (subject === "") {
      setErrMsg("Plese give your Subject!");
    } else if (message === "") {
      setErrMsg("Message is required!");
    } else {
      emailjs
        .sendForm(
          "service_ctfub99",
          "template_g3un4oi",
          formRef.current,
          "BXTMZEEnxxOlV0yU5"
        )
        .then(
          (result) => {
            console.log(result.text);
            setSuccessMsg(
              `Thank you dear ${username}, Your Messages has been sent Successfully!`
            );
            setErrMsg("");
            setUsername("");
            setPhoneNumber("");
            setEmail("");
            setSubject("");
            setMessage("");
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };
  return (
    <section
      id="contact"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title title="CONTACT" des="Let's connet!" />
      </div>
      <div className="w-full">
        <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
          <ContactLeft />
          <div className={`w-full lgl:w-[60%] h-full py-10 bg-[#0e0f11] flex flex-col gap-8 p-4 lgl:p-8 rounded-lg ${darkMode?'shadow-shadowOne':''}`}>
            <form
              ref={formRef}
              onSubmit={handleSend}
              className="w-full flex flex-col gap-4 lgl:gap-6 py-2 lgl:py-5"
            >
              {errMsg && (
                <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-orange-500 text-base tracking-wide animate-bounce">
                  {errMsg}
                </p>
              )}
              {successMsg && (
                <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-green-500 text-base tracking-wide animate-bounce">
                  {successMsg}
                </p>
              )}
              <div className="w-full flex flex-col lgl:flex-row gap-10">
                <div className="w-full lgl:w-1/2 flex flex-col gap-4">
                  <p className={`text-sm text-gray-400 uppercase tracking-wide ${darkMode?'text-gray-400':'text-lightText'}`}>
                    Your name
                  </p>
                  <input
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    name="user_name"
                    className={`${
                      errMsg === "Username is required!" &&
                      "outline-designColor"
                    } ${darkMode?'contactInputDark':'contactInputLight'}`}
                    type="text"
                  />
                </div>
                <div className="w-full lgl:w-1/2 flex flex-col gap-4">
                  <p className={`text-sm text-gray-400 uppercase tracking-wide ${darkMode?'text-gray-400':'text-lightText'}`}>
                    Phone Number
                  </p>
                  <input
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                    name="user_number"
                    className={`${
                      errMsg === "Phone number is required!" &&
                      "outline-designColor"
                    } ${darkMode?'contactInputDark':'contactInputLight'}`}
                    type="text"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <p className={`text-sm text-gray-400 uppercase tracking-wide ${darkMode?'text-gray-400':'text-lightText'}`}>
                  Email
                </p>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  name="user_email"
                  className={`${
                    errMsg === "Please give your Email!" &&
                    "outline-designColor"
                  } ${darkMode?'contactInputDark':'contactInputLight'}`}
                  type="email"
                />
              </div>
              <div className="flex flex-col gap-4">
                <p className={`text-sm text-gray-400 uppercase tracking-wide ${darkMode?'text-gray-400':'text-lightText'}`}>
                  Subject
                </p>
                <input
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                  name="user_subject"
                  className={`${
                    errMsg === "Plese give your Subject!" &&
                    "outline-designColor"
                  } ${darkMode?'contactInputDark':'contactInputLight'}`}
                  type="text"
                />
              </div>
              <div className="flex flex-col gap-4">
                <p className={`text-sm text-gray-400 uppercase tracking-wide ${darkMode?'text-gray-400':'text-lightText'}`}>
                  Message
                </p>
                <textarea
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  name="message"
                  className={`${
                    errMsg === "Message is required!" && "outline-designColor"
                  } ${darkMode?'contactTextAreaDark':'contactTextAreaLight'}`}
                  cols="30"
                  rows="8"
                ></textarea>
              </div>
              <div className="w-full">
                <button
                  type="submit"
                  className={`w-full h-12 ${darkMode?'bg-[#141518] text-gray-400 hover:text-white':'bg-gray-300 text-darkText hover:text-black'} rounded-lg text-base tracking-wider uppercase  duration-300 hover:border-[1px] hover:border-designColor border-transparent`}
                >
                  Send Message
                </button>
              </div>
              {errMsg && (
                <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-orange-500 text-base tracking-wide animate-bounce">
                  {errMsg}
                </p>
              )}
              {successMsg && (
                <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-green-500 text-base tracking-wide animate-bounce">
                  {successMsg}
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
