import React, { useContext } from "react";
import { ThemeContext } from "../../context";
import { BsGithub } from "react-icons/bs";
import { FaReact, FaNodeJs, FaAngular, FaHtml5, FaCss3Alt } from "react-icons/fa";
import { SiMongodb } from "react-icons/si";
import { SiNextdotjs } from "react-icons/si";

const ProjectsCard = ({ title, des, src, githubLink, tags }) => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  const tagsIcon = [
    {
      name: "react",
      icon: <FaReact />,
    },
    {
      name: "angular",
      icon: <FaAngular />,
    },
    {
      name: "next",
      icon: <SiNextdotjs />,
    },
    {
      name: "express",
      icon: <SiMongodb />,
    },
    {
      name: "html",
      icon: <FaHtml5 />,
    },
    {
      name: "css",
      icon: <FaCss3Alt />
      ,
    },
    { name: "node", icon: <FaNodeJs /> },
  ];
  return (
    <div className={`w-full p-4 xl:px-12 h-auto xl:py-10 rounded-lg flex flex-col ${darkMode?' bg-[#0e0f11] group hover:bg-gradient-to-b hover:from-gray-900 hover:gray-900':'shadow-xl bg-[#d3dae3]'}  transition-colors duration-1000`}>
      <div className="w-full h-[80%] overflow-hidden rounded-lg">
        <img
          className="w-full h-60 object-cover group-hover:scale-110 duration-300 cursor-pointer"
          src={src}
          alt="src"
        />
      </div>
      <div className="w-full mt-5 flex flex-col  gap-6">
        <div>
          <div className="flex items-center justify-between">
            <h3 className="text-base uppercase text-designColor font-medium">
              {title}
            </h3>
            <div className="flex gap-2">
              <span className={`text-lg w-10 h-10 rounded-full ${darkMode?'bg-black':'bg-white shadow-lg'} bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer`}>
                <a href={githubLink} target="_blank" rel="noreferrer">
                  <BsGithub />
                </a>
              </span>
            </div>
          </div>
          <p className="text-sm tracking-wide mt-3 hover:text-gray-100 duration-300">
            {des}
          </p>
          <div className="flex gap-2 pt-8">
            {tags &&
              tagsIcon.map((tag) => (
                <>
                  {tags?.includes(tag.name) && (
                    <span className={`text-2xl w-12 h-12 rounded-full ${darkMode?'bg-black':'bg-white shadow-lg'} inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer`}>
                      {tag.icon}
                    </span>
                  )}
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsCard;
