import React, { useState, useContext } from "react";
import Slider from "react-slick";
import { RiStarFill } from "react-icons/ri";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi";
import Title from "../layouts/Title";
import { quote, clientIcon } from "../../assets";
import { ThemeContext } from "../../context";

function SampleNextArrow(props) {
  const { onClick } = props;
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div
      className={`w-14 h-12 bg-[#0c1821]  ${darkMode?'shadow-shadowOne':''} hover:bg-black duration-300 rounded-md text-2xl text-gray-400 flex justify-center items-center absolute top-0 right-0  cursor-pointer z-10`}
      onClick={onClick}
    >
      <HiArrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div
      className={`w-14 h-12  ${darkMode?'shadow-shadowOne':''} bg-[#0c1821] hover:bg-black duration-300 rounded-md text-2xl text-gray-400 flex justify-center items-center absolute top-0 right-20 cursor-pointer z-10`}
      onClick={onClick}
    >
      <HiArrowLeft />
    </div>
  );
}

const Testimonial = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  const [dotActive, setDocActive] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },
    appendDots: (dots) => (
      <div
        style={{
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <ul
          style={{
            display: "flex",
            gap: "15px",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {" "}
          {dots}{" "}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === dotActive
            ? {
                width: "12px",
                height: "12px",
                color: "blue",
                background: "#ff014f",
                borderRadius: "50%",
                cursor: "pointer",
              }
            : {
                width: "12px",
                height: "12px",
                color: "blue",
                background: "gray",
                borderRadius: "50%",
                cursor: "pointer",
              }
        }
      ></div>
    ),
  };
  return (
    <section
      id="testimonial"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title title="WHAT CLIENTS SAY" des="Testimonial" />
      </div>
      <div className="max-w-6xl mx-auto">
        {/* ================ Slider One ================== */}
        <Slider {...settings}>
          <div className="w-full">
            <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
              <div className={`w-full lgl:w-[35%] h-full bg-[#0e0f11] p-8 rounded-lg ${darkMode?'shadow-shadowOne':''} flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center`}>
                <img
                  className="h-72 md:h-32 lgl:h-72 rounded-lg object-contain"
                  src={clientIcon}
                  alt="testimonialOne"
                />
                <div className="w-full flex flex-col justify-end">
                  <p className="text-xs uppercase text-designColor tracking-wide mb-2">
                    United States - Breinigsville
                  </p>
                  <h3 className={`text-2xl font-bold  ${darkMode?'':'text-gray-300'}`}>Upwork Client</h3>
                </div>
              </div>
              <div className="w-full lgl:w-[60%] h-full flex flex-col justify-between">
                <img className="w-20 lgl:w-32" src={quote} alt="quote" />
                <div className={`w-full h-[70%] py-10 bg-[#0e0f11] rounded-lg ${darkMode?'shadow-shadowOne':''} p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8`}>
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-gray-900">
                    <div>
                      <h3 className={`text-xl lgl:text-2xl font-medium tracking-wide ${darkMode?'':'text-gray-300'}`}>
                        React + Tailwind Developers to Test Styling App
                      </h3>
                      <p className="text-center text-base text-gray-400 mt-3">
                        via Upwork - Sep 6, 2022 - Sep 14, 2022
                      </p>
                    </div>
                    <div className="text-yellow-500 flex gap-1 pt-2">
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-gray-400 font-medium tracking-wide leading-6">
                    Uzman went above and beyond the call of duty to provide us
                    with excellent feedback on our new product. He has strong
                    communication skills that complement his technical
                    knowledge. He has my highest recommendation to any future
                    client!
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* ================ Slider Two ================== */}

          <div className="w-full">
            <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
              <div className={`w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-8 rounded-lg ${darkMode?'shadow-shadowOne':''} flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center`}>
                <img
                  className="h-72 md:h-32 lgl:h-72 rounded-lg object-contain"
                  src={clientIcon}
                  alt="testimonialTwo"
                />
                <div className="w-full flex flex-col justify-end">
                  <p className="text-xs uppercase text-designColor tracking-wide mb-2">
                    Turkey - Istanbul
                  </p>
                  <h3 className={`text-2xl font-bold  ${darkMode?'':'text-gray-300'}`}>Upwork Client</h3>
                </div>
              </div>
              <div className="w-full lgl:w-[60%] h-full flex flex-col justify-between">
                <img className="w-20 lgl:w-32" src={quote} alt="quote" />
                <div className={`w-full h-[70%] py-10 bg-gradient-to-r from-[#1e2024] to-[#23272b] rounded-lg ${darkMode?'shadow-shadowOne':''} p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8`}>
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-gray-900">
                    <div>
                      <h3 className={`text-xl lgl:text-2xl font-medium tracking-wide ${darkMode?'':'text-gray-300'}`}>
                        Improve my webpage GUI
                      </h3>
                      <p className="text-center text-base text-gray-400 mt-3">
                        via Upwork - May 13, 2023 - May 19, 2023
                      </p>
                    </div>
                    <div className="text-yellow-500 flex gap-1 pt-2">
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Testimonial;
